const axios = require('axios').default,
    networkProviders = require('../helpers/network-providers');

const subscribe = async (email, address, language='en-EN') => {
    let response;
    const data = {
            email,
            address,
            language
        },
        url = networkProviders.alertsUrl + '/subscribe';

    try {
        response = await axios.post(url, data);
    }
    catch (error) {
        response = error;
    }
    return response;
};

module.exports = {
    subscribe
};
